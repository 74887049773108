// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faTimes as fasFaTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faPhone as fasFaPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";

import { faFacebookF as fabFaFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";

/**
 * Add solid icons
 */
library.add(fasFaTimes, fasFaPhone);

/**
 * Add brands icons
 */
library.add(fabFaFacebookF);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
